import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { Container, createTheme, TextField, ThemeProvider } from '@mui/material';
import { useCreateAppMutation, useReplyOnMutation } from '../../redux/slices/Applications.slice';
import { styled } from '@mui/system';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { FilePicker } from './FilePicker/FilePicker';
import tokenService from '../../services/token.service';
import { IDetecting, IEstate, IRegistry } from '../../types/Applications.type';
import { useAppSelector } from '../../redux/Store';
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DropZone } from "./FilePicker/drop-zone";
import styles from "./FilePicker/file-picker.module.css";
import { FilesList } from "./FilePicker/files-list";


interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const defaultTheme = createTheme();


let arr: { [key: string]: string } = {
  'docx': 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
  'doc': 'data:application/msword;base64,',
  'xlsx': 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
  'xls': 'data:application/vnd.ms-excel;base64,',
  'csv': 'data:text/csv;base64,',
  'txt': 'data:text/plain;base64,',
  'pdf': 'data:application/pdf;base64,',
  'jpeg': 'data:image/jpeg;base64,',
  'png': 'data:image/png;base64,'
}



export default function ModalDetective({ isOpen, setIsOpen, application, type }: { type: "Estate" | "Registry" | "Detecting", isOpen: boolean, application: IDetecting | IRegistry | IEstate, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
  const [open, setOpen] = React.useState(isOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setIsOpen(false);
  const [reply] = useReplyOnMutation()
  const [fileData, setFileData] = React.useState()


  const [create, { isLoading }] = useCreateAppMutation()


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // console.log(event.currentTarget);
    if (event) {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      console.log(data);
      console.log('files:', files);
      if (files.length != 0) {
        console.log('fewfwef');
        files.forEach(async (file: any) => {
          await getBase64(file.file).then(async (res: any) => {
            if (data)
              await reply({
                app_id: application.id,
                comment: data.get('comment'),
                type: type,
                data: res
              })
                .then(res => {
                  setFiles([])
                  setIsOpen(false)
                })
          });
        });
        setUploadStarted(false);
      }
      else {
        await reply({
          app_id: application.id,
          comment: data.get('comment'),
          type: type,
          data: null
        })
          .then(res => {
            setFiles([])
            setIsOpen(false)
          })
      }
    }
  };

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false);

  // handler called when files are selected via the Dropzone component
  const handleOnChange = useCallback((files: any) => {
    let filesArray = Array.from(files) as any;

    filesArray = filesArray.map((file: any) => ({
      id: nanoid(),
      file
    })) as any;
    console.log(filesArray);
    setFiles(filesArray);
    setProgress(0);
    setUploadStarted(false);
  }, []);

  // handle for removing files form the files list view
  const handleClearFile = useCallback((id: any) => {
    setFiles((prev) => prev.filter((file: any) => file.id !== id));
  }, []);

  // whether to show the progress bar or not
  const canShowProgress = useMemo(() => files.length > 0, [files.length]);
  const getBase64 = (file: any) => {
    return new Promise(async resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Called", reader);
        baseURL = reader.result as any;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };
  // execute the upload operation
  const handleUpload = useCallback(async () => {
    try {
      const data = []
      if (files) {

        files.forEach(async (file: any) => {
          await getBase64(file.file).then(async (res: any) => {
            data.push(res)
            console.log('data12:', application);
            // await reply({
            //   app_id: application.id,
            //   data: res,
            //   type: type
            // })
            // handleSubmit()
            // handleClose(false)
          });
        });

        setUploadStarted(false);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  }, [files.length]);

  // set progress to zero when there are no files
  useEffect(() => {
    if (files.length < 1) {
      setProgress(0);
    }
  }, [files.length]);

  // set uploadStarted to false when the upload is complete
  useEffect(() => {
    if (progress === 100) {
      setUploadStarted(false);
    }
  }, [progress]);

  const uploadComplete = useMemo(() => progress === 100, [progress]);


  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition

            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}
          >
            <Fade in={isOpen}>
              <Box sx={style}>
                <div className="overflow-scroll h-[70vh]">

                  <Typography component="h1" variant="h5">
                    Комментарий
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <textarea
                      className='w-full p-3 text-lg shadow-lg border-2'
                      name="comment"
                      id="comment"
                      aria-comment="minimum height"
                      disabled={tokenService.getUser()?.role == 'admin' ? true : false}
                      placeholder="Комментарий"
                      defaultValue={application?.Replye && application.Replye.comment}

                    />

                    {tokenService.getUser()?.role == 'detective' &&
                      <div className={styles.wrapper}>
                        {/* canvas */}
                        <div className={styles.canvas_wrapper}>
                          <DropZone onChange={handleOnChange} accept={undefined} />
                        </div>

                        {/* files listing */}
                        {files.length ? (
                          <div className={styles.files_list_wrapper}>
                            <FilesList
                              files={files}
                              onClear={handleClearFile}
                              uploadComplete={uploadComplete}
                            />
                          </div>
                        ) : null}

                        <button
                          type="submit"
                          className={classNames(
                            styles.upload_button,
                            ""
                          )}
                        >
                          {`Отправить`}
                        </button>

                      </div>
                    }{
                      <div className='flex flex-col gap-2 '>
                        {(application as any)?.data?.map((el: any) => {
                          return <a target='_blank' href={arr[el.filename.split('.')[1] as string] + el.data} download={`${el.filename}`}>{el.filename}</a>
                        })}
                      </div>
                    }
                  </Box>
                </div>
              </Box>
            </Fade>
          </Modal>
        </Container>

      </ThemeProvider>
    </div>
  );
}