import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { Container, createTheme, ThemeProvider } from '@mui/material';
import { useCreateAppMutation, useCreateAppRRMutation } from '../../redux/slices/Applications.slice';
import { IDetecting, IEstate, IRegistry } from '../../types/Applications.type';
import * as XLSX from 'xlsx';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs'

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  overflowY: 'auto',
  overflowX: '0',

  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: 1,
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
};

const allowedExtensions = ["csv", 'xls', 'xlsx'];

const defaultTheme = createTheme();


export default function ModalComponent({ isOpen, setIsOpen, refetch, type }: { isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, refetch: any, type: "Estate" | "Registry" | "Detecting" }) {
  const [open, setOpen] = React.useState(isOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setIsOpen(false);


  const [create, { isLoading }] = useCreateAppMutation()
  const [createRR] = useCreateAppRRMutation()

  const [dataFromFile, setData] = React.useState<IRegistry[] | IEstate[] | IDetecting[] | []>([]);

  const [dataFromFileRR, setDataRR] = React.useState<{
    address: string,
  } | null>(null);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = React.useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = React.useState("");

  // This function will be called when
  // the file input changes
  const handleFileChange = (e: any) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension =
        inputFile?.type.split("/")[1];
      if (
        !allowedExtensions.includes(fileExtension)
      ) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = async (e: any) => {
    setData([])
    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    switch (type) {
      case 'Detecting':
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 0,
          defval: "",
          skipHidden: true,
          raw: false
        });
        jsonData.map((item: any, index: number) => {
          if (index > 0) {
            let res: any = Object.values(item)
            if (res[3] != '') {
              setData(prev => [...prev, {
                fio: res[3].trim(),
                dateBirth: dayjs(res[4] as any).format('DD.MM.YYYY'),
                address: res[5].trim(),
                passport: res[6].trim(),
                inn: res[7].trim(),
                snils: res[8].trim(),
                VU: res[9].trim(),
                placeOfBirth: res[10].trim()
              }]);

            }
          }
        })
        break;
      case 'Registry': {
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
          raw: false
        });
        jsonData.map((item: any, index: number) => {
          let res: any = Object.values(item)
          if (res[0] != '')
            setData((prev: any) => [...prev, { address: res[0].trim() } as IRegistry]);
        })
        break;
      }
      case 'Estate': {
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 0,
          defval: "",
          raw: false
        });
        jsonData.map((item: any, index: number) => {

          let res: any = Object.values(item)
          console.log(res);

          setData((prev: any) => [...prev, {
            fio: res[1].trim(),
            dateBirth: dayjs(res[2] as any).format('DD.MM.YYYY'),
            inn: res[3].trim(),
            comment: res[4].trim()
          }]);
        })
        break;
      }
    }
  };


  const handleSubmit = async () => {
    console.log(dataFromFile)
    await create({
      type: type,
      data: JSON.stringify(dataFromFile)
    })
      .then(() => {
        setData([])
        setIsOpen(false)
      })
  };

  const handleSubmitRR = async (event: React.FormEvent<HTMLFormElement>) => {
    console.log(event.currentTarget);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
    if (data)
      await createRR({
        type: type as "Имущество" | "реестр" | "установка",
        address: data.get('address') as string
      })
        .then(() => {
          setIsOpen(false)
        })
  };

  let [page, setPage] = React.useState(1);
  const handleChange = (value: any) => {
    console.log(value);
    setPage(Number(value.target.innerText));
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                TransitionComponent: Fade,
              },
            }}

          >
            <Fade in={isOpen}>
              <Box sx={style}>
                <div className=" h-[70vh]">

                  <Typography component="h1" variant="h5" className='p-2'>
                    Новые заявки
                  </Typography>
                  <hr />

                  {type == 'Detecting' &&
                    <Box component='section' sx={{ mt: 1 }} className='shadow-xl p-3'>
                      <div className='flex gap-2 flex-col'>
                        <label htmlFor="file" className='text-lg'>Создание из файла</label>
                        <input
                          onInput={(e) => handleParse(e)}
                          id="csvInput"
                          name="file"
                          type="File"
                        />
                      </div>
                      <div className='flex flex-row items-center gap-2 my-4'>
                        <hr className='w-full h-[2px] bg-black ' />
                        <span>
                          либо
                        </span>
                        <hr className='w-full h-[2px] bg-black ' />

                      </div>
                      <div className='flex items-center justify-center my-3'>
                        {
                          dataFromFile.length != 0 &&
                          <Stack spacing={2}>
                            <Pagination count={dataFromFile.length} onChange={handleChange} />
                          </Stack>
                        }

                      </div>
                      {
                        (dataFromFile as IDetecting[]).map((dataFromFile, index) => {
                          if (index == (page - 1))
                            return <div className='flex flex-col gap-3' key={index}>
                              <input
                                id="fio"
                                name="fio"
                                placeholder='ФИО'
                                className='w-full p-3 text-lg shadow-lg border-2'
                                defaultValue={(dataFromFile as IDetecting)?.fio ?? ''}
                                onChange={(e) => {
                                  dataFromFile.fio = e.target.value
                                }}
                              />
                              <input
                                id="dateBirth"
                                name="dateBirth"
                                placeholder='Дата рождения'
                                className='w-full p-3 text-lg shadow-lg border-2'
                                defaultValue={(dataFromFile as IDetecting)?.dateBirth ?? ''}
                                onChange={(e) => {
                                  dataFromFile.dateBirth = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'

                                name="address"
                                placeholder="Адрес"
                                type="address"
                                id="address"
                                defaultValue={(dataFromFile as IDetecting)?.address ?? ''}
                                onChange={(e) => {
                                  dataFromFile.address = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'

                                name="passport"
                                placeholder="Паспорт"
                                type="passport"
                                id="passport"
                                defaultValue={(dataFromFile as IDetecting)?.passport ?? ''}
                                onChange={(e) => {
                                  dataFromFile.passport = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'

                                name="inn"
                                placeholder="ИНН"
                                type="inn"
                                id="inn"
                                defaultValue={(dataFromFile as IDetecting)?.inn ?? ''}
                                onChange={(e) => {
                                  dataFromFile.inn = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'
                                name='snils'

                                placeholder="СНИЛС"
                                type="snils"
                                id="snils"
                                defaultValue={(dataFromFile as IDetecting)?.snils ?? ''}
                                onChange={(e) => {
                                  dataFromFile.snils = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'
                                placeholder="ВУ"
                                name='VU'
                                type="VU"
                                id="VU"
                                defaultValue={(dataFromFile as IDetecting)?.VU ?? ''}
                                onChange={(e) => {
                                  dataFromFile.VU = e.target.value
                                }}
                              />
                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'

                                name="placeOfBirth"
                                placeholder="Место рождения"
                                type="placeOfBirth"
                                id="placeOfBirth"
                                defaultValue={(dataFromFile as IDetecting)?.placeOfBirth ?? ''}
                                onChange={(e) => {
                                  dataFromFile.placeOfBirth = e.target.value
                                }}
                              />

                              <input
                                className='w-full p-3 text-lg shadow-lg border-2'
                                name="comment"
                                placeholder="Комментарий"
                                type="comment"
                                id="comment"
                                defaultValue={(dataFromFile as IDetecting)?.comment ?? ''}
                                onChange={(e) => {
                                  dataFromFile.comment = e.target.value
                                }}
                              />
                            </div>
                        })
                      }
                      <Button
                        type="submit"
                        fullWidth
                        color='success'
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSubmit()}
                      >
                        Создать
                      </Button>

                    </Box>}
                  {type == 'Estate' &&
                    <Box component="section" sx={{ mt: 1 }}>
                      <div className='flex gap-2 flex-col'>
                        <label htmlFor="file" className='text-lg'>Создание из файла</label>
                        <input
                          onInput={(e) => handleParse(e)}
                          id="csvInput"
                          name="file"
                          type="File"
                        />
                      </div>
                      <div className='flex flex-row items-center gap-2 my-4'>
                        <hr className='w-full h-[2px] bg-black ' />
                        <span>
                          либо
                        </span>
                        <hr className='w-full h-[2px] bg-black ' />

                      </div>
                      <div className='flex items-center justify-center my-3'>
                        {
                          dataFromFile.length != 0 &&
                          <Stack spacing={2}>
                            <Pagination count={dataFromFile.length} onChange={handleChange} />
                          </Stack>
                        }

                      </div>
                      {(dataFromFile as IEstate[]).map((dataFromFile, index) => {
                        if (index == (page - 1))
                          return <div className='flex flex-col gap-3'>
                            <input
                              id="fio"
                              name="fio"
                              placeholder='ФИО'
                              className='w-full p-3 text-lg shadow-lg border-2'
                              defaultValue={(dataFromFile as IEstate)?.fio ?? ''}
                              onChange={(e) => {
                                dataFromFile.fio = e.target.value
                              }}
                            />
                            <input
                              id="dateBirth"
                              name="dateBirth"
                              placeholder='Дата рождения'
                              className='w-full p-3 text-lg shadow-lg border-2'
                              defaultValue={(dataFromFile as IDetecting)?.dateBirth ?? ''}
                              onChange={(e) => {
                                dataFromFile.dateBirth = e.target.value
                              }}
                            />
                            <input 
                              className='w-full p-3 text-lg shadow-lg border-2'

                              name="inn"
                              placeholder="ИНН"
                              type="inn"
                              id="inn"
                              defaultValue={(dataFromFile as IEstate)?.inn ?? ''}
                              onChange={(e) => {
                                dataFromFile.inn = e.target.value
                              }}
                            />
                            <input
                              className='w-full p-3 text-lg shadow-lg border-2'

                              name="comment"
                              placeholder="Комментарий"
                              type="comment"
                              id="comment"
                              defaultValue={(dataFromFile as IEstate)?.comment ?? ''}
                              onChange={(e) => {
                                dataFromFile.comment = e.target.value
                              }}
                            />
                          </div>
                      })}

                      <Button
                        type="submit"
                        fullWidth
                        color='success'
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSubmit()}
                      >
                        Создать
                      </Button>

                    </Box>}
                  {type == 'Registry' &&
                    <Box component="section" sx={{ mt: 1 }}>
                      <div className='flex gap-2 flex-col'>
                        <label htmlFor="file" className='text-lg'>Создание из файла</label>
                        <input
                          onInput={(e) => handleParse(e)}
                          id="csvInput"
                          name="file"
                          type="File"
                        />
                      </div>
                      <div className='flex flex-row items-center gap-2 my-4'>
                        <hr className='w-full h-[2px] bg-black ' />
                        <span>
                          либо
                        </span>
                        <hr className='w-full h-[2px] bg-black ' />
                      </div>
                      <div className='flex flex-col gap-3'>
                        {(dataFromFile as IRegistry[]).map((dataFromFile) => {
                          return <input
                            id="address"
                            name="address"
                            placeholder='Адрес'
                            className='w-full p-3 text-lg shadow-lg border-2'
                            defaultValue={(dataFromFile as IRegistry)?.address ?? ''}
                            onChange={(e) => {
                              dataFromFile.address = e.target.value
                            }}
                          />

                        })}
                      </div>

                      <Button
                        type="submit"
                        fullWidth
                        color='success'
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSubmit()}
                      >
                        Создать
                      </Button>

                    </Box>}
                </div>
              </Box>
            </Fade>
          </Modal>
        </Container>

      </ThemeProvider>
    </div>
  );
}