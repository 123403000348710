import { FunctionComponent, useEffect, useLayoutEffect, useState } from "react";
import tokenService from "../../services/token.service";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";

import ModalComponent from "../helpers/ModalComponent";
import { useGetAllMutation } from "../../redux/slices/Applications.slice";
import ModalDetective from "../helpers/ModalDetective";
import { useRefreshTokenMutation } from "../../redux/slices/Auth.slice";
import { IDetecting, IEstate, IRegistry } from "../../types/Applications.type";
interface ApplicationProps {

}

enum translator {
    'type' = 'Тип',
    'id' = 'ID',
    'fio' = "ФИО",
    'dateBirth' = "Дата рождения",
    'address' = "Адрес",
    'passport' = "Паспорт",
    'inn' = "ИНН",
    'snils' = "СНИЛС",
    'VU' = "ВУ",
    'placeOfBirth' = "Место рождения",
    'status' = "Статус",
    'dataState' = 'dataState',
    'comment' = 'Комментарий'
}


const Application: FunctionComponent<ApplicationProps> = () => {
    const [type, setType] = useState<"Estate" | "Registry" | "Detecting">('Detecting')

    const [getData, { isLoading, isSuccess, reset }] = useGetAllMutation()
    const [dataState, setData] = useState<IDetecting[] | IRegistry[] | IEstate[]>()

    useEffect(() => {
        setData(undefined)
        getData({
            type: type
        }).then((res) => {
            setData(res.data as any)
        })
    }, [type])


    const [refreshToken] = useRefreshTokenMutation()

    const navigate = useNavigate()
    const [page, setPage] = useState(0);
    const [currectApplication, setCurrentApplication] = useState<IRegistry | IDetecting | IEstate>()
    const [open, setOpen] = useState(false);
    const [openDetective, setOpenDetective] = useState(false);


    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (!open &&
             !openDetective) {
            setData(undefined)
            getData({
                type: type
            }).then((res) => {
                setData(res.data as any)
            })
        }
    }, [open, openDetective])

    useLayoutEffect(() => {
        console.log('test');

        if (tokenService.getUser())
            refreshToken({
                refreshToken: tokenService.getLocalRefreshToken()
            })
                .unwrap()
                .then(responce => {
                    tokenService.updateLocalAccessToken(responce.accessToken)
                    tokenService.updateLocalRefreshToken(responce.refreshToken)
                })
                .catch(err => {
                    console.log(err);
                    navigate('/login')
                })
        else {
            console.log("Работает else");

            window.location.replace("/login")
        }
    }, [])


    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as "Estate" | "Registry" | "Detecting");
    };
    return (
        <section className="flex flex-col gap-4 max-w-screen-2xl mx-auto">
            <div className="w-full shadow-lg bg-slate-400 border-b-2 p-4 flex flex-row justify-between">
                <div className="flex flex-row gap-4 items-center">
                    <h1 className="text-2xl">
                        Заявки
                    </h1>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder='Тип'
                        className='w-full'
                        value={type}
                        onChange={handleChange}
                    >
                        <MenuItem value={'Estate'}>Имущество</MenuItem>
                        <MenuItem value={"Registry"}>Реестр</MenuItem>
                        <MenuItem value={"Detecting"}>Установка</MenuItem>
                    </Select>
                </div>
                <div className="flex flex-row gap-4 items-center">
                    {tokenService.getUser()?.username && <>
                        <span>
                            {tokenService.getUser()?.username}
                        </span>
                        <ExitToAppIcon className="cursor-pointer" onClick={() => {
                            tokenService.removeUser()
                            navigate('/login')
                        }} />

                    </>
                    }
                </div>
            </div>
            <div className=" flex flex-col gap-4 p-4">
                {tokenService.getUser()?.role == 'admin' &&
                    <Button variant="contained" onClick={() => setOpen(true)}>Создание заявок</Button>}
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    {isLoading && <div>Loading</div>}
                    {dataState?.length! > 0 && isSuccess ?
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {Object.keys(dataState![0]).map((column, index) => (
                                                
                                                
                                                <TableCell
                                                    key={index}
                                                    align={'center'}
                                                    style={{ minWidth: '100px' }}
                                                >
                                                    {(translator as any)[column]}
                                                </TableCell>

                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataState!
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((rows, index) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index} >
                                                        {Object.entries(rows).map((row: any) => {
                                                            return typeof row[1] !== 'object' &&
                                                                <TableCell key={index} align="center">
                                                                    {row[0] == 'dateBirth' ? row[1].toString().split('T')[0] : row[0] == 'status' ? row[1] == false ? 'Не рассмотрено' : 'Рассмотрено' : row[1] != null && row[1].toString()}
                                                                </TableCell>

                                                        })}
                                                        {(tokenService.getUser()?.role && (rows as any).status == true) &&
                                                            (<TableCell key={index} align="center">
                                                                <Button variant="contained" color="primary" onClick={() => {
                                                                    setCurrentApplication(rows)
                                                                    setOpenDetective(true)
                                                                }}>
                                                                    Посмотреть ответ
                                                                </Button>
                                                            </TableCell>
                                                            )
                                                        }
                                                        {tokenService.getUser()?.role == 'detective' && (rows as any).status == false &&
                                                            (<TableCell key={index} align="center">
                                                                <Button variant="contained" color="primary" onClick={() => {
                                                                    setCurrentApplication(rows)
                                                                    setOpenDetective(true)
                                                                }}>
                                                                    Ответить
                                                                </Button>
                                                            </TableCell>)
                                                        }
                                                        {/* {(tokenService.getUser()?.role && rows.status == true) &&
                                                            (<TableCell key={index} align="center">
                                                                <Button variant="contained" color="primary" onClick={() => {
                                                                    setCurrentApplication(rows)
                                                                    setOpenDetective(true)
                                                                }}>
                                                                    Посмотреть ответ
                                                                </Button>
                                                            </TableCell>
                                                            )} */}
                                                    </TableRow>

                                                )
                                            })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={dataState!.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />

                        </>
                        :
                        <>
                            <div>
                                <Typography component="h1" variant="h5">
                                    История заявок пуста
                                </Typography>
                            </div>
                        </>}
                </Paper>
            </div>
            <ModalComponent refetch={reset} isOpen={open} setIsOpen={setOpen} type={type} />
            <ModalDetective type={type} isOpen={openDetective} setIsOpen={setOpenDetective} application={currectApplication!} />
        </section >
    );
}

export default Application;