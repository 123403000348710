// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-zone_wrapper__5elN9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .drop-zone_banner_text__MSKnD {
    font-size: 1.5rem;
    color: #ccc;
    display: block;
    margin: 0.5rem 0;
  }
  
  .drop-zone_banner__Xg6zT {
    background-color: #fafafa;
    width: 100%;
    border: 4px dashed #ccc;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .drop-zone_input__-w0YB {
    width: 100%;
    height: 100%;
    display: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/helpers/FilePicker/drop-zone.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;EACf","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n  }\n  \n  .banner_text {\n    font-size: 1.5rem;\n    color: #ccc;\n    display: block;\n    margin: 0.5rem 0;\n  }\n  \n  .banner {\n    background-color: #fafafa;\n    width: 100%;\n    border: 4px dashed #ccc;\n    height: 200px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 1rem;\n    cursor: pointer;\n  }\n  \n  .input {\n    width: 100%;\n    height: 100%;\n    display: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `drop-zone_wrapper__5elN9`,
	"banner_text": `drop-zone_banner_text__MSKnD`,
	"banner": `drop-zone_banner__Xg6zT`,
	"input": `drop-zone_input__-w0YB`
};
export default ___CSS_LOADER_EXPORT___;
