// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files-list_files_list__MLLih {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }
  
  .files-list_files_list_item__kKQg4 {
    width: calc(100% - 0.5rem);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0;
    display: grid;
    grid-template-columns: calc(100% - 1rem) 1rem;
  
    &:not(:last-child) {
      border-bottom: 1px solid #f4f4f4;
    }
  }
  
  .files-list_files_list_item_name__3YUGl {
    color: #0073cf;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
  }
  
  .files-list_file_list_item_progress__iGqHJ {
    width: 100%;
    margin-left: auto;
  }
  
  .files-list_file_list_item_check__otoRB {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #008000;
  }
  
  .files-list_files_list_item_clear__nsILS {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    &:hover {
      color: #0073cf;
    }
  
    svg {
      width: 100%;
      height: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/helpers/FilePicker/files-list.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,iBAAiB;IACjB,aAAa;IACb,6CAA6C;;IAE7C;MACE,gCAAgC;IAClC;EACF;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;;IAEf;MACE,cAAc;IAChB;;IAEA;MACE,WAAW;MACX,YAAY;IACd;EACF","sourcesContent":[".files_list {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .files_list_item {\n    width: calc(100% - 0.5rem);\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    padding: 0.5rem 0;\n    display: grid;\n    grid-template-columns: calc(100% - 1rem) 1rem;\n  \n    &:not(:last-child) {\n      border-bottom: 1px solid #f4f4f4;\n    }\n  }\n  \n  .files_list_item_name {\n    color: #0073cf;\n    font-weight: 500;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    width: 100%;\n    text-align: left;\n  }\n  \n  .file_list_item_progress {\n    width: 100%;\n    margin-left: auto;\n  }\n  \n  .file_list_item_check {\n    width: 1rem;\n    height: 1rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #008000;\n  }\n  \n  .files_list_item_clear {\n    width: 16px;\n    height: 16px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n  \n    &:hover {\n      color: #0073cf;\n    }\n  \n    svg {\n      width: 100%;\n      height: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files_list": `files-list_files_list__MLLih`,
	"files_list_item": `files-list_files_list_item__kKQg4`,
	"files_list_item_name": `files-list_files_list_item_name__3YUGl`,
	"file_list_item_progress": `files-list_file_list_item_progress__iGqHJ`,
	"file_list_item_check": `files-list_file_list_item_check__otoRB`,
	"files_list_item_clear": `files-list_files_list_item_clear__nsILS`
};
export default ___CSS_LOADER_EXPORT___;
