// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-picker_wrapper__VZXoi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.file-picker_canvas_wrapper__Hpr46 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.file-picker_files_list_wrapper__vx7UT {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.file-picker_files_list_progress_wrapper__6iHr0 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
}

.file-picker_upload_button__YJcls {
  min-width: 100px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #0073cf;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;

  &.file-picker_disabled__IuWQt {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/helpers/FilePicker/file-picker.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB;IACE,sBAAsB;IACtB,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 4px;\n}\n\n.canvas_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.files_list_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.files_list_progress_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  margin: 1rem 0;\n}\n\n.upload_button {\n  min-width: 100px;\n  height: 40px;\n  border: none;\n  border-radius: 4px;\n  background-color: #0073cf;\n  color: #fff;\n  font-size: 1rem;\n  font-weight: 500;\n  cursor: pointer;\n  outline: none;\n  white-space: nowrap;\n  padding: 0.25rem 0.5rem;\n\n  &.disabled {\n    background-color: #ccc;\n    color: #fff;\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `file-picker_wrapper__VZXoi`,
	"canvas_wrapper": `file-picker_canvas_wrapper__Hpr46`,
	"files_list_wrapper": `file-picker_files_list_wrapper__vx7UT`,
	"files_list_progress_wrapper": `file-picker_files_list_progress_wrapper__6iHr0`,
	"upload_button": `file-picker_upload_button__YJcls`,
	"disabled": `file-picker_disabled__IuWQt`
};
export default ___CSS_LOADER_EXPORT___;
